<template>
  <div class="changePswd">
    <div class="changeBox">
        <el-divider content-position="left">{{ i18nData.name }}</el-divider>
      <el-form
        class="formBox"
        :model="formData"
        :rules="rules"
        ref="formData"
        :hide-required-asterisk="true"
        label-position="top"
      >
        <!-- <el-tabs class="typeTabs" v-model="activeType" @tab-click="handleClick">
          <el-tab-pane :label="i18nData.typephone" name="mobile"></el-tab-pane>
          <el-tab-pane :label="i18nData.typeemail" name="email"></el-tab-pane>
        </el-tabs> -->
        <div class="avatarBox">
          <span class="label">
            {{ i18nData.avatar }}
          </span>
          <el-upload
            class="avatar-uploader"
            :action="this.$api.baseUrl + 'api/common/upload'"
            :headers="{ token: this.$store.state.vuex_token }"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <el-image
              class="img avatar"
              v-if="imageUrl"
              :src="imageUrl"
              fit="cover"
            >
            </el-image>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </div>

        <el-form-item
          class="formItem"
          prop="nickname"
          :label="i18nData.nickname"
        >
          <el-input
            class="input"
            v-model="formData.nickname"
            :placeholder="i18nData.nicknametip"
          ></el-input>
        </el-form-item>
        <el-form-item class="formItem" prop="company" :label="i18nData.company">
          <el-input
            class="input"
            v-model="formData.company"
            :placeholder="i18nData.companytip"
          ></el-input>
        </el-form-item>
        <el-form-item class="formItem">
          <el-button
            class="submitBtn"
            type="primary"
            @click="submitForm('formData')"
            >{{ i18nData.button }}</el-button
          >
        </el-form-item>
      </el-form>
      <el-divider content-position="left">{{ i18nData.phone }}</el-divider>
      <el-form
        class="formBox"
        :model="formData"
        :rules="rules"
        ref="formPhone"
        :hide-required-asterisk="true"
        label-position="top"
      >
        <el-form-item class="formItem" prop="phone" :label="i18nData.phone">
          <el-input
            class="input"
            v-model="formData.phone"
            :placeholder="i18nData.phonetips"
          ></el-input>
        </el-form-item>
        <div class="codeBox" v-if="isModifyPhone">
          <el-form-item class="formItem" prop="code" :label="i18nData.code">
            <el-input
              class="input"
              v-model="formData.code"
              :placeholder="i18nData.codetips"
            ></el-input>
          </el-form-item>
          <span class="code-img">
            <el-button
              @click="getCenterCode('formPhone','phone','changemobile')"
              type="primary"
              plain
              :disabled="isDisabled"
              >{{
                isDisabled ? countText.count + i18nData.changecode : i18nData.code
              }}</el-button
            >
          </span>
        </div>
        <el-form-item class="formItem" v-if="!isModifyPhone">
          <el-button
            class="submitBtn"
            type="primary"
            @click="clickModifyPhone()"
            >{{i18nData.phonebutton}}</el-button
          >
        </el-form-item>
        <el-form-item class="formItem" v-else>
          <el-button
            class="submitBtn"
            type="primary"
            @click="submitFormPhone('formPhone')"
            >{{i18nData.button}}</el-button
          >
        </el-form-item>
      </el-form>
      <el-divider content-position="left">{{ i18nData.email }}</el-divider>
      <el-form
        class="formBox"
        :model="formData"
        :rules="rules"
        ref="formEmail"
        :hide-required-asterisk="true"
        label-position="top"
      >
        <el-form-item class="formItem" prop="email" :label="i18nData.email">
          <el-input
            class="input"
            v-model="formData.email"
            :placeholder="i18nData.emailtips"
          ></el-input>
        </el-form-item>
        <div class="codeBox" v-if="isModifyEmail">
          <el-form-item class="formItem" prop="emailcode" :label="i18nData.emailcode">
            <el-input
              class="input"
              v-model="formData.emailcode"
              :placeholder="i18nData.emailcodetips"
            ></el-input>
          </el-form-item>
          <span class="code-img">
            <el-button
              @click="getCenterCode('formEmail','email','changeemail')"
              type="primary"
              plain
              :disabled="isDisabled"
              >{{
                isDisabled ? countText.count + i18nData.changecode : i18nData.emailcode
              }}</el-button
            >
          </span>
        </div>
        <el-form-item class="formItem" v-if="!isModifyEmail">
          <el-button
            class="submitBtn"
            type="primary"
            @click="clickModifyEmail()"
            >{{i18nData.emailbutton}}</el-button
          >
        </el-form-item>
        <el-form-item class="formItem" v-else>
          <el-button
            class="submitBtn"
            type="primary"
            @click="submitFormEmail('formEmail')"
            >{{i18nData.button}}</el-button
          >
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { loginfunc, getcodefunc } from "./../common/top.mixin.js";

export default {
  name: "mycenter",
  mixins: [loginfunc, getcodefunc],
  components: {},
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      } else {
        const reg = /^1[3|4|5|6|7|8][0-9]\d{8}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        // if (this.formData.password !== "") {
        //   this.$refs.formData.validateField("password");
        // }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入新密码"));
      } else if (value !== this.formData.password) {
        callback(new Error("两次输入新密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      imageUrl: "",
      isModifyPhone:false,
      isModifyEmail:false,
      activeType: "phone",
      formData: {
        avatar: "",
        nickname: "",
        phone: "",
        email: "",
        company: "",
        password: "",
        pswdAgain: "",
        code: "",
        emailcode:""
      },
      rules: {
        nickname: [
          { required: true, message: "请输入联系人姓名", trigger: "blur" }
        ],
        company: [
          { required: true, message: "请输入企业/院校", trigger: "blur" }
        ],
        pswdAgain: [{ validator: validatePass2, trigger: "blur" }],
        phone: [{ validator: checkPhone, trigger: "blur" }],
        email: [
          { required: true, message: "请输入邮箱地址", trigger: "blur" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: "blur"
          }
        ],
        password: [{ validator: validatePass, trigger: "blur" }],
        code: [{ required: true, message: "请输入手机验证码", trigger: "blur" }],
        emailcode: [{ required: true, message: "请输入邮箱验证码", trigger: "blur" }]
      }
    };
  },
  mounted() {
    this.$api.https.getUserIndex();
  },
  created() {
    this.$store.state.activeIndex = "";
    this.$store.state.myActiveIndex = this.$route.name;
    this.formData.avatar = this.vuex_user.avatar || "";
    this.imageUrl = this.vuex_user.avatar || "";
    this.formData.nickname = this.vuex_user.nickname || "";
    this.formData.email = this.vuex_user.email || "";
    this.formData.phone = this.vuex_user.mobile || "";
    this.formData.company = this.vuex_user.bio || "";
  },
  computed: {
    vuex_user() {
      console.log(this.$store.state.vuex_user);
      return this.$store.state.vuex_user;
    }
  },
  methods: {
    clickModifyPhone(){
        this.isModifyPhone = true;
        this.isModifyEmail = false;
    },
    clickModifyEmail(){
        this.isModifyEmail = true;
        this.isModifyPhone = false;
    },
    handleClick(tab, event) {
      console.log(tab, event);
      if (this.activeType == "phone") {
        this.formData.email = "";
      } else {
        this.formData.phone = "";
      }
      this.$refs["formData"].clearValidate();
    },
    submitForm(formName) {
      console.log(this.$refs[formName]);
      this.$refs[formName].validate(async valid => {
        if (valid) {
          let params = {
            username: this.vuex_user.username,
            // mobile: this.formData.mobile,
            // email: this.formData.email,
            avatar: this.formData.avatar,
            nickname: this.formData.nickname,
            bio: this.formData.company
          };
          let res = await this.$api.https.getUserProfile(params);
          if (!res.code) {
            this.$message({
              message: res.msg,
              type: "error",
              offset: 40
            });
            return;
          }
          this.$message({
            message: res.msg,
            type: "success",
            offset: 40
          });
          //   this.success(4);
          // this.$router.push({ path: "/my" });
        } else {
          this.$message({
            message: "个人资料验证失败",
            type: "error",
            offset: 40
          });
          return false;
        }
      });
    },
    submitFormPhone(formName) {
      console.log(this.$refs[formName]);
      this.$refs[formName].validate(async valid => {
        if (valid) {
          let params = {
            mobile: this.formData.phone,
            captcha: this.formData.code,
          };
          let res = await this.$api.https.getChangeMobile(params);
          if (!res.code) {
            this.$message({
              message: res.msg,
              type: "error",
              offset: 40
            });
            return;
          }
          this.$message({
            message: res.msg,
            type: "success",
            offset: 40
          });
          this.isModifyPhone = false;
          //   this.success(4);
          // this.$router.push({ path: "/my" });
        } else {
          this.$message({
            message: "手机号修改失败",
            type: "error",
            offset: 40
          });
          return false;
        }
      });
    },
    submitFormEmail(formName) {
      console.log(this.$refs[formName]);
      this.$refs[formName].validate(async valid => {
        if (valid) {
          let params = {
            email: this.formData.email,
            captcha: this.formData.emailcode,
          };
          let res = await this.$api.https.getChangeEmail(params);
          if (!res.code) {
            this.$message({
              message: res.msg,
              type: "error",
              offset: 40
            });
            return;
          }
          this.$message({
            message: res.msg,
            type: "success",
            offset: 40
          });
          this.isModifyEmail = false;
          //   this.success(4);
          // this.$router.push({ path: "/my" });
        } else {
          this.$message({
            message: "邮箱修改失败",
            type: "error",
            offset: 40
          });
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.formData.avatar = res.data.fullurl;
      console.log(res);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG/png 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    }
  }
};
</script>
<style lang="scss" scoped>
$vue_color: #0495f3;
.avatar-uploader {
  border: 1px solid #d9d9d9;
  width: 120px;
  border-radius: 10px;
  overflow: hidden;
  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
  }
  .avatar {
    width: 120px;
    height: 120px;
    display: block;
  }
}

.changePswd {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 24px;
  text-align: left;
  padding: 40px 40px 60px 40px;
  .changeBox {
    width: 100%;
    padding: 40px 0;
    .formBox {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      .typeTabs {
        margin-bottom: 10px;
      }
      ::v-deep.el-tabs__nav-wrap::after {
        background-color: transparent;
      }
      ::v-deep .el-form-item__label {
        padding: 0;
        color: #a3a3a3;
      }
      .formItem {
        // margin-bottom: 5px;
        width: 50%;
        .input {
          width: 280px;
        }
        ::v-deep.el-input__inner {
          border-radius: 5px;
        }
      }
      .codeBox {
        display: flex;
        width: 280px;
        .formItem {
          .input {
            width: 140px;
          }
        }
        .code-img {
          flex: 1;
          margin-top: 40px;
          ::v-deep .el-button--primary.is-plain {
            width: 100%;
            padding: 12px 0;
            background-color: #ffffff;
            border-color: $vue_color;
            color: $vue_color;
          }
          ::v-deep .el-button--primary.is-plain:focus,
          .el-button--primary.is-plain:hover {
            background-color: $vue_color;
            color: #ffffff;
            transition: 0.5s;
          }
          ::v-deep .el-button--primary.is-plain.is-disabled,
          .el-button--primary.is-plain.is-disabled:active,
          .el-button--primary.is-plain.is-disabled:focus,
          .el-button--primary.is-plain.is-disabled:hover {
            background-color: #ebeef5;
            border-color: #c0c4cc;
            color: $vue_color;
          }
        }
      }

      .avatarBox {
        width: 100%;
        display: flex;
        flex-direction: column;
        > .label {
          color: #a3a3a3;
          font-size: 14px;
        }
      }
      .submitBtn {
        margin-top: 30px;
        background-color: $vue_color;
        border-color: $vue_color;
        width: 120px;
        height: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        border-radius: 4px;
        color: #ffffff !important;
      }
    }
  }
}
</style>
